import axios from "axios"
import { unix } from "dayjs"
import { Balance, Contact, GetUserOutput, Transaction, User, UserReadonlyProperties, UserUpdate } from "./models"
import { centsToDollars, toCents } from "./utils"

function getBaseURL() {
  if (document.location.host.includes('localhost')) {
    return 'http://localhost:8080'
  } else {
    return `https://api.${document.location.host}`
  }
}

export async function GetUser(id: string, token: string): Promise<GetUserOutput> {
  const headers = { Authorization: `${token}` };

  const userData = await axios.get(getBaseURL() + '/v1/user?userID=' + id, { headers })
  console.log('user data', userData)

  let lastEvaluatedKey = undefined
  let tournamentDataObjects = []
  do {
    console.log('paging through tournaments')
    const body: any = { userID: id, lastEvaluatedKey: lastEvaluatedKey }
    let tournamentsURL = getBaseURL() + '/v1/user/tournaments'
    const tournamentsData = await axios.post(tournamentsURL, body, { headers }).then(res => res.data)
    lastEvaluatedKey = tournamentsData.lastEvaluatedKey
    tournamentDataObjects.push(tournamentsData)
  } while (lastEvaluatedKey)

  console.log('tournament data objects', tournamentDataObjects)

  const mergedTournamentData = tournamentDataObjects.reduce((a, b) => {
    a.Tournaments.push(...b.Tournaments)
    a.TopOpponents.push(...b.TopOpponents)
    return a
  })

  const final = { ...userData.data, ...mergedTournamentData }
  return parseUserData({ data: final })
}

function parseUserData(res: any) {
  const contactInfo: Contact = {
    Email: res.data.Contact?.Email ?? 'N/A',
    Name: res.data.Contact?.FirstName + ' ' + res.data.Contact?.LastName,
    Phone: res.data.Contact?.PhoneNumber
  }

  const user: User = {
    Gems: res.data.User?.Balance_Gems as string,
    CashCents: res.data.User?.Balance_Cash,
    BonusCashCents: res.data.User?.Balance_CashBonus,
    Level: res.data.User?.Level as string,
    XPInLevel: res.data.User?.XPInLevel as string,
    Status: res.data.User?.UserStatus as string,
    Suspended: res.data.User?.Suspended as boolean ?? false,
    Email: res.data.User?.Email ?? 'N/A'
  }

  const output: GetUserOutput = {
    User: user,
    UserStats: extractUserStats(res),
    UserGameStats: res.data.UserGameStats,
    Balance: extractBalance(res),
    Contact: contactInfo,
    Transactions: res.data.Transactions,
    Tournaments: res.data.Tournaments,
    TopOpponents: res.data.TopOpponents
  }

  if (res.data.WithdrawalRequest?.Withdraw) {
    output.WithdrawalRequest = res.data.WithdrawalRequest?.Withdraw
  }

  return output
}

function extractBalance(res: any) {
  const gems = res.data.User?.Balance_Gems
  const cash = res.data.User?.Balance_Cash
  const bonusCash = res.data.User?.Balance_CashBonus

  var balance = new Map<string, string>()

  balance.set(Balance.Balance_Gems, gems)
  balance.set(Balance.Balance_Cash, centsToDollars(cash))
  balance.set(Balance.Balance_CashBonus, centsToDollars(bonusCash))

  return balance
}

function extractUserStats(res: any) {
  const keys = Object.keys(UserReadonlyProperties).filter((v) => isNaN(Number(v)))
  const values = Object.values(UserReadonlyProperties).filter((v) => isNaN(Number(v)))

  var readonly = new Map<string, string>()

  var result: any = {}
  for (var i = 0; i < keys.length; i++) {
    result[keys[i]] = values[i]
  }

  const moneyProps: string[] = [UserReadonlyProperties.TotalDepositedAmount, UserReadonlyProperties.TotalWithdrawnAmount]
  for (const key of keys) {
    var value = res.data.User[key] ?? '0'
    if (moneyProps.includes(key)) value = centsToDollars(value)
    readonly.set(result[key], value)
  }

  const entryFeeTransactions = res.data.Transactions.filter((t: any) => t.Type === 'ENTRY_FEE')
  const entryFeeCount = entryFeeTransactions.length
  const totalEntryFeeSpent = entryFeeTransactions.reduce((a: any, b: any) => (a ?? 0) + (b.Cash ?? 0), 0)

  readonly.set('Total Spent on Entry Fees', centsToDollars(Math.abs(totalEntryFeeSpent)))
  readonly.set('Entry Fees Count', entryFeeCount)
  if (res.data.User?.ActivityLog.length > 0) {
    const firstActivity = JSON.parse(res.data.User?.ActivityLog[0]);
    readonly.set('First Time Active', unix(firstActivity.Timestamp ?? 0).format('DD/MM/YYYY HH:mm:ss'));
  }
  if (res.data.User?.LastActiveTimestamp) {
    readonly.set('Last Time Active', unix(res.data.User?.LastActiveTimestamp ?? 0).format('DD/MM/YYYY HH:mm:ss'))
  }

  if (res.data.User?.PVPRating) {
    readonly.set('PVPRating', res.data.User?.PVPRating)
  }

  handleScoreStats(res, readonly)

  const transactions = res.data.Transactions
  const totalDepositedAmount = transactions.filter((t: any) => t.Type === 'DEPOSIT').reduce((a: any, b: any) => (a ?? 0) + (b.Cash ?? 0) - (b.BonusCash ?? 0), 0)
  readonly.set('TotalDepositedAmount', centsToDollars(totalDepositedAmount))

  const pvpTournaments = res.data.Tournaments.filter((t: any) => t.TournamentDefinition.TournamentType.startsWith('PVP'))
  const pvpBalance = getBalanceForTournaments(pvpTournaments)

  const pncTournaments = res.data.Tournaments.filter((t: any) => t.TournamentDefinition.TournamentType.startsWith('PNC'))
  const pncBalance = getBalanceForTournaments(pncTournaments)

  readonly.set('PVPCashBalance', centsToDollars(pvpBalance.cashBalance))
  readonly.set('PVPGemsBalance', pvpBalance.gemsBalance.toString())

  readonly.set('PNCCashBalance', centsToDollars(pncBalance.cashBalance))
  readonly.set('PNCGemsBalance', pncBalance.gemsBalance.toString())

  readonly.set('Country', res.data.User?.CountryCode ?? 'N/A')

  const habitCents = median(res.data.User?.LastDepositsAmounts ?? [0])
  const habitDollars = centsToDollars(habitCents)
  readonly.set('Habit', habitDollars)

  return readonly
}

function handleScoreStats(res: any, readonly: Map<string, string>) {
  const pncLastCashScores: number[] = res.data.User?.PNCLastCashScores
  // const cashAverage = pncLastCashScores.reduce((a: any, b: any) => (a ?? 0) + (b ?? 0), 0) / pncLastCashScores.length
  const cashMedian = median(pncLastCashScores)

  // readonly.set('Trickshots Cash Average', Math.trunc(cashAverage).toString())
  readonly.set('Trickshots Cash Median', Math.trunc(cashMedian).toString())

  const pncLastGemsScores: number[] = res.data.User?.PNCLastGemsScores
  // const gemsAverage = pncLastGemsScores.reduce((a: any, b: any) => (a ?? 0) + (b ?? 0), 0) / pncLastGemsScores.length
  const gemsMedian = median(pncLastGemsScores)

  // readonly.set('Trickshots Gems Average', Math.trunc(gemsAverage).toString())
  readonly.set('Trickshots', Math.trunc(gemsMedian).toString())

  const pncLastCashMomentumScores = res.data.User?.TrickshotsMomentumLastCashScores ?? []
  const cashMomentumMedian = median(pncLastCashMomentumScores)
  readonly.set('Trickshots Momentum Cash Median', Math.trunc(cashMomentumMedian).toString())

  const pncLastGemsMomentumScores = res.data.User?.TrickshotsMomentumLastGemsScores ?? []
  const gemsMomentumMedian = median(pncLastGemsMomentumScores)
  readonly.set('Trickshots Momentum Gems Median', Math.trunc(gemsMomentumMedian).toString())

  const pncLastGemsTrickshots9BallScores = res.data.User?.Trickshots9BallLastGemsScores ?? []
  const gemsTrickshots9BallMedian = median(pncLastGemsTrickshots9BallScores)
  readonly.set('Trickshots 9 Ball Gems Median', Math.trunc(gemsTrickshots9BallMedian).toString())

  const pncLastCashTrickshots9BallScores = res.data.User?.Trickshots9BallLastCashScores ?? []
  const cashTrickshots9BallMedian = median(pncLastCashTrickshots9BallScores)
  readonly.set('Trickshots 9 Ball Cash Median', Math.trunc(cashTrickshots9BallMedian).toString())
}

function median(numbers: number[]): number {
  if (numbers.length === 0) return 0;
  const sortedNumbers = [...numbers].sort((a, b) => a - b);

  const middleIndex = Math.floor(sortedNumbers.length / 2);

  // If even number of elements, take the average of the two middle numbers
  if (sortedNumbers.length % 2 === 0) {
    return (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2;
  }

  return sortedNumbers[middleIndex];
}

function getBalanceForTournaments(tournaments: any) {
  const totalTournamentsCashEntryFees = tournaments.reduce((a: any, b: any) => a + (b.EntryFeeTransaction?.CashCents ?? 0), 0)
  const totalTournamentsCashPrize = tournaments.reduce((a: any, b: any) => a + (b.PrizeAmountCash ?? 0), 0)
  const cashBalance = totalTournamentsCashPrize - totalTournamentsCashEntryFees

  const totalTournamentsGemsEntryFees = tournaments.reduce((a: any, b: any) => a + (b.EntryFeeTransaction?.Gems ?? 0), 0)
  const totalTournamentsGemsPrize = tournaments.reduce((a: any, b: any) => a + (b.PrizeAmountGems ?? 0), 0)
  const gemsBalance = totalTournamentsGemsPrize - totalTournamentsGemsEntryFees

  return { cashBalance, gemsBalance }
}

export async function NewTransaction(userID: string, transaction: Transaction, token: string, completion: () => any): Promise<void> {
  const headers = { Authorization: `${token}` };

  const gems = transaction.Gems
  const cash = transaction.Cash + transaction.BonusCash
  const bonusCash = transaction.BonusCash

  const body: any = { Gems: gems, Cash: toCents(cash), BonusCash: toCents(bonusCash), UserID: userID }
  console.log('new transaction body', body)
  return axios.post(getBaseURL() + '/v1/transaction', body, { headers }).then(res => { console.log(res); completion() })
}

export async function GetLogs(tournamentId: string, token: string): Promise<string> {
  const headers = { Authorization: `${token}` };
  var url = getBaseURL() + '/v1/get-logs?tournamentId=' + tournamentId;
  console.log('url', url)
  return axios.get(url, { headers: headers }).then(res => {
    console.log('res', res);
    return res.data.URL;
  });
}

export async function UpdateUser(user: UserUpdate, token: string, completion: () => any): Promise<void> {
  console.log('update user', user)
  const headers = { Authorization: `${token}` };
  return axios.post(getBaseURL() + '/v1/user', user, { headers }).then(res => { console.log(res); completion() })
}

export async function SettleWithdrawal(userID: string, approved: boolean, token: string, completion: (success: boolean) => any) {
  const url = getBaseURL() + '/v1/user/withdrawal'
  const body = { UserID: userID, Approve: approved }
  const headers = { Authorization: `${token}` }
  return axios.post(url, body, { headers: headers }).then(res => {
    console.log('settle withdrawal response', res)
    completion(true)
  }).catch(error => {
    console.log('withdrawal settlement error', error)
    completion(false)
  })
}

export async function DeleteUser(userID: string, token: string, completion: (success: boolean) => any) {
  const url = getBaseURL() + '/v1/delete/user'
  const headers = { Authorization: `${token}` }
  const body = { UserID: userID }
  return axios.post(url, body, { headers: headers }).then(res => {
    console.log('delete user response', res)
    completion(true)
  }).catch(error => {
    console.log('delete user error', error)
    completion(false)
  })
}

export async function downloadGameMoves(tournamentID: string, userID: string, token: string, completion: (data: any, error?: Error) => any) {
  const url = getBaseURL() + '/v1/download-game-moves'
  const headers = { Authorization: `${token}` }
  const body = { TournamentID: tournamentID, UserID: userID }

  return axios.post(url, body, { headers: headers }).then(res => {
    completion(res.data, undefined)
    console.log('download game moves response', res)
  }).catch(error => {
    completion(undefined, error)
    console.log('download game moves error', error)
  })
}